import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-dark pt-6 pb-2 footer text-white">
      <div className="container container-xxl">
        <div className="row">
          <div className="col-md-6 col-sm-6 mb-6 mb-md-0">
            <Link className="d-block mb-2 mt-3" to="/">
              <img src="Image/logoFull3.png" alt="Dreamland" className="w-25" />   
            </Link>
            <div className="lh-18 " style={{opacity:".8"}}>
              <p className="mb-3">
              Ravindrapuri Extension Varanasi (UP) 
                <br />
                Pin 221005
              </p>
              <Link
              style={{ textDecoration: "none" }}
                className="d-block text-white hover-white"
                to="mailto:"
              >
                <i className="fa fa-envelope text-warning" /> rkconsultancy@gmail.com
              </Link>
              <Link
              style={{ textDecoration: "none" }}
                className="d-block text-lighter  fs-6 hover-white text-white"
                to="tel:+91-8081333444"
              >
                <i className="fa fa-phone-alt text-warning" /> +91-8789363567
              </Link>
              <Link
              style={{ textDecoration: "none" }}
                className="d-block text-lighter  fs-6 hover-white text-white"
                to="tel:+91-8081333444"
              >
                <i className="fa fa-phone-alt text-warning" /> +91-9931288287
              </Link>
              
              <Link className="d-block text-white hover-white" to="/" style={{ textDecoration: "none" }}>
                <i className="fa fa-globe" /> www.rkclifestyle.com
              </Link>
            </div>
            <ul className="list-inline mb-0 mt-3" style={{opacity:".8"}}>
              <li className="list-inline-item mr-0">
                <Link
                  to="#"
                  className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f" />
                </Link>
              </li>
              <li className="list-inline-item mr-0">
                <Link
                  to="#"
                  className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in" />
                </Link>
              </li>
              <li className="list-inline-item mr-0">
                <Link
                  to="#"
                  className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Link>
              </li>
              <li className="list-inline-item mr-0">
                <Link
                  to="https://youtube.com/"
                  className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  target="_blank"
                >
                  <i className="fab fa-youtube" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-6 mb-6 mb-md-0">
            <h4 className="text-white fs-16 my-4  mt-5">
              Useful Links
            </h4>
            <ul className="list-group list-group-flush list-group-no-border">
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1 hover-white"
                  to="/"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  Home
                </Link>
              </li>
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1  hover-white"
                  to="/about"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  About-us
                </Link>
              </li>
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1  hover-white"
                  to="/testimonial"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  Testimonials
                </Link>
              </li>
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1 hover-white"
                  to="/contact"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="col-md-4 col-sm-4 mb-6 mb-md-0">
            <h4 className="text-white fs-16 my-4 font-weight-700 mt-5">Projects</h4>
            <ul className="list-group list-group-flush list-group-no-border">
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1 hover-white"
                  to="mulberry-heights"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  Dreamland Mulberry Heights
                </Link>
              </li>
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1 hover-white"
                  to="celebrity-greens"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  Dreamland Celebrity Greens
                </Link>
              </li>
              <li className="list-group-item bg-transparent p-0 right-chev">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white lh-1 hover-white"
                  to="mulberry-villas"
                >
                    <i className="fa-solid fa-circle-chevron-right text-warning me-1"></i>
                  Dreamland Mulberry Villas
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="mt-2 row row" style={{opacity:".8"}}>
          <p className="col-md-auto mb-0 text-white mr-auto">
            © RK Consultancy - 2023. All Rights Reserved
          </p>
          <p className="col-md-auto  mb-0 text-white ">
            Designed &amp; Developed by - &nbsp;
            <Link to="https://kiezensoft.com/" target="_blank" style={{textDecoration:"none",color:"orange"}}>
              Kiezen Soft Tech
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
