import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard';
import { API } from '../../../API/RealEstate_API';
import { ToastContainer, toast } from "react-toastify";

function AllEnquiry() {

    const [enquiry, setEnquiry] = useState([]);

    useEffect(() => {
        API.GetEnquiry().then((response) => {
            setEnquiry(response.data);
        })
    }, [])

    const removeQuery = (id) => {
        API.RemoveEnquiry(id).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
            }
        })
        API.GetEnquiry().then((response) => {
            if (response.data.length > 0) {
                setEnquiry(response.data);
            }
            else {
                setEnquiry([]);
            }
        })
    }
    return (
        <div className="row">
            <ToastContainer position="top-center" theme="light" />
            <h4 style={{ textAlign: "center" }}>All Enquiry</h4>
            {enquiry.length > 0 ? (enquiry.map((item) => {
                return (
                    <div className="col-xl-6 col-md-6 mb-2 mt-3" key={item.id}>
                        <div className="card p-2 mb-2 rounded-0" style={{ backgroundColor: "rgb(245, 236, 219)", display: "flex" }}>
                            <div className="mx-3">
                                <p className="fs-6">Enquiry by <b className='text-dark'>{item.name}</b></p>
                                <span>
                                    Email: {item.email}
                                </span><br />
                                <span>
                                    Phone: {item.phone}
                                </span>
                                <p>{item.message}</p>
                            </div>
                            <div className="">
                                <button className='btn btn-danger btn-sm mx-auto d-block' onClick={() => removeQuery(item.id)}>Remove</button>
                            </div>
                        </div>
                    </div>
                )
            })) : (<p className="text-center mt-5" style={{ fontSize: "16px" }}>No Enquiries Available</p>)}
        </div>
    )
}

export default Dashboard(AllEnquiry);
