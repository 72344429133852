import React from "react";
import { Link,useLocation} from "react-router-dom";
import "./Navbar.css";
import cityProperty from "./cityProperty.json";

export default function Navbar() {
  const location=useLocation();

  const {pathname}=location;

  const splitLocation=pathname.split("/");
  return (
    <>
      <nav className="navbar navbar-expand-lg  sticky-top head-navbar">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
          <img src="Image/logo.png" alt="Dreamland" className="w-25" style={{height:'30px'}} />   
            <span className="log">RK CONSULTANCY</span>
            
          </Link>
          <button
            className="navbar-toggler btn btn-outline-primary btn-sm ms-auto"
            type="button"
            style={{ marginRight: "15px" }}
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse mx-auto "
            id="navbarResponsive"
          >
            <ul className="navbar-nav">
              <li className={splitLocation[1]==="" ? "active nav-item":"nav-item"}>
                <Link to="/" className="item text-decoration-none">
                  Home
                </Link>
              </li>
              <li className={splitLocation[1]==="about" ? "active nav-item":"nav-item"}>
                <Link to="/about" className="item text-decoration-none">
                  About
                </Link>
              </li>
              <li className={splitLocation[1]==="gallery" ? "active nav-item":"nav-item"}>
                <Link to="/gallery" className="item text-decoration-none">
                  Gallery
                </Link>
              </li>
              <li className={splitLocation[1]==="services" ? "active nav-item":"nav-item"}>
                <Link to="/services" className="item text-decoration-none">
                  Services
                </Link>
              </li>
              <li className={splitLocation[1]==="activity" ? "active nav-item":"nav-item"}>
                <Link to="/activity" className="item text-decoration-none">
                Activity
                </Link>
              </li>
              <li className={splitLocation[1]==="packages" ? "active nav-item":"nav-item"}>
                <Link to="/packages" className="item text-decoration-none">
                Packages
                </Link>
              </li>
              <li className={splitLocation[1]==="testimonial" ? "active nav-item":"nav-item"}>
                <Link to="/testimonial" className="item text-decoration-none">
                  Testimonial
                </Link>
              </li>
              {/* <li className={splitLocation[1]==="all-property" ? "active nav-item dropdown":"nav-item dropdown"}>
                <Link
                  className="item text-decoration-none"
                  id="dropdownMenuButton"
                  to="/all-property"
                >
                  Find Property
                  <i className="fa fa-caret-down" style={{ opacity: ".8" }}></i>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {cityProperty.map((data, index) => {
                    return (
                      <li key={index}>
                        <Link className="dropdown-item" to={data.link}>
                          {data.city}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className={splitLocation[1]==="post-property" ? "active nav-item":"nav-item"}>
                <Link to="/post-property" className="item text-decoration-none">
                  Post Property
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="item text-decoration-none"
                  id="dropdownMenuButton"
                >
                  Projects{" "}
                  <i className="fa fa-caret-down" style={{ opacity: ".8" }}></i>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <Link className="dropdown-item" to="/celebrity-greens">
                      Dreamland Celebrity Greens
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/mulberry-villas">
                      Dreamland Mulberry Villas
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/mulberry-heights">
                      Dreamland Mulberry Heights
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className={splitLocation[1]==="contact" ? "active nav-item":"nav-item"}>
                <Link to="/contact" className="item text-decoration-none">
                  Contact
                </Link>
              </li>
              <li className={splitLocation[1]==="admin-login" ? "active nav-item":"nav-item"}>
                <Link to="/admin-login" className="item text-decoration-none">
                  Admin
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
