import React, { useState } from "react";
import "./Services.css";
import "../Home/Enquiry.css";
import { FaArrowDown } from "react-icons/fa";
import $ from "jquery";
import { API } from "../../API/RealEstate_API";
import { useNavigate } from "react-router-dom";

function Enquery() {

  const navigate = useNavigate();
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    enquiry_for: "",
    message: "",
  });

  const enquiryChange = (e) => {
    setEnquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const enquirySubmit = (e) => {
    e.preventDefault();
    var val = document.forms["enquiry_form"];
    if (!val["name"].value) {
      document.getElementById("name_error").style.display = "block";
    }
    else if (!val["email"].value) {
      document.getElementById("email_error").style.display = "block";
      document.getElementById("name_error").style.display = "none";
    }
    else if (!val["phone"].value) {
      document.getElementById("phone_error").style.display = "block";
      document.getElementById("name_error").style.display = "none";
      document.getElementById("email_error").style.display = "none";
    }
    else if (!val["message"].value) {
      document.getElementById("message_error").style.display = "block";
      document.getElementById("phone_error").style.display = "none";
      document.getElementById("name_error").style.display = "none";
      document.getElementById("email_error").style.display = "none";
    }
    else if (val["name"].value && val["email"].value && val["phone"].value && val["message"].value) {
      API.AddEnquiry(enquiryData).then((response) => {
        if (response.success === true) {
          navigate("/thanks");
        }
      })
      setEnquiryData({
        name: "",
        email: "",
        phone: "",
        enquiry_for: "",
        message: "",
      })
    }
  };
  const enquiryNow = () => {
    $("#form_wrap").slideToggle();
  };

  return (
    <>
      <button
        type="button"
        className="enquiry-button mx-auto d-block mt-5"
        onClick={enquiryNow}
      >
        Enquiry Now
        <FaArrowDown className="mx-2 text-white fs-5" />
      </button>

      <div className="form_wrap" id="form_wrap">
        <div className="form_wrap_head">
          <p>Enquiry Details</p>
          <p>
            <span style={{ color: "red" }}>*</span>fields are mandatory
          </p>
        </div>
        <form onSubmit={enquirySubmit} id="enquiry_form">
        <div className="row clearfix">
            <div className="col_half">
              <label htmlFor="name">Name</label>
              <div className="input_field">
                <span>
                  <i aria-hidden="true" className="fa fa-user" />
                </span>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={enquiryChange}
                  value={enquiryData.name}
                  placeholder="Your name"
                  required=""
                />
              </div>
              <p id="name_error">Please Enter Name</p>
            </div>
            <div className="col_half">
              <label htmlFor="email">Email</label>
              <div className="input_field">
                <span>
                  <i aria-hidden="true" className="fa fa-envelope" />
                </span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={enquiryChange}
                  value={enquiryData.email}
                  placeholder="Your email"
                />
              </div>
              <p id="email_error">Please Enter Email</p>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col_half">
              <label htmlFor="phone">Phone</label>
              <div className="input_field">
                <span>
                  <i aria-hidden="true" className="fa fa-phone" />
                </span>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone no"
                  onChange={enquiryChange}
                  value={enquiryData.phone}
                  pattern="[0-9]{10}"
                />
              </div>
              <p id="phone_error">Please Enter Phone Number</p>
            </div>
            <div className="col_half">
              <label htmlFor="property">Enquiry For</label>
              <div className="input_field">
                <span>
                  <i aria-hidden="true" className="fa fa-search" />
                </span>
                <select
                  name="property"
                  id="property"
                  onChange={enquiryChange}
                  value={enquiryData.enquiry_for}
                >
                  <option value="Buy">Buy a property</option>
                  <option value="Rent-In">Rent-In property</option>
                  <option value="Rent-Out">Rent-Out property</option>
                  <option value="Sell">Sell a property</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div>
              <label htmlFor="message">Message</label>
              <div className="textarea_field">
                <span>
                  <i aria-hidden="true" className="fa fa-comment" />
                </span>
                <textarea
                  cols={46}
                  rows={3}
                  name="message"
                  id="message"
                  onChange={enquiryChange}
                  value={enquiryData.message}
                />
              </div>
              <p id="message_error">Please Enter Message</p>
            </div>
          </div>
          <div className="button_wrap">
            <button type="submit" className="btn btn-danger">Submit</button>
            <button
              type="button"
              className="btn btn-danger mx-1"
              onClick={enquiryNow}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Enquery;
