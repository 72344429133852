import React, { useEffect, useState } from "react";
import { API } from "../../../API/RealEstate_API";
import Dashboard from "../Dashboard";
import "../Dashboard.css";
import "../../Testimonial/Testimonial.css";

function ApprovedTestimonial() {
  const [approvedTestimonial, setApprovedTestimonial] = useState([]);

  useEffect(() => {
    API.GetApprovedTestimonial().then((response) => {
      setApprovedTestimonial(response.data);
    });
  }, []);

  let star = [];
  let num=0;
  const printStar = (count) => {
    star = [];
    num=0;
    for (let i = 0; i < 5; i++) {
      if (i < count) {
        star.push(<i className="fa-solid fa-star"></i>);
      } else {
        star.push(<i className="fa-regular fa-star"></i>);
      }
    }
  };
  return (
    <div
      className="row testimonial-page-container"
    >
      <h4 style={{ textAlign: "center" }}>Approved Testimonial</h4>
      {approvedTestimonial.length>0?(approvedTestimonial.map((test) => {
        return (
          <div className="col-xl-10 mb-2 mt-3" key={test.id}>
            <div className="card p-2">
              <p className="font-weight-bold">Review by {test.name}</p>
              <span>
                {printStar(test.rating)}
                {star.map((item, index) => {
                  return <span key={index}>{item}</span>;
                })}&nbsp;&nbsp;{num}/5
              </span>
              <p>{test.review}</p>
              <span></span>
            </div>
          </div>
        );
      })):(<p className="text-center mt-5" style={{fontSize:"16px"}}>No Testimonials Available</p>)}
    </div>
  );
}

export default Dashboard(ApprovedTestimonial);
