import React, { useEffect, useState } from "react";
import Slider1 from "../Slider/Slider1";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../API/RealEstate_API";
import Enquiry from "./Enquiry";
import Typewriter from "typewriter-effect";

function Home({ approvedTestimonial, callbackProperty }) {
  const navigate = useNavigate();
  const [selectiveProperty, setSelectiveProperty] = useState([]);

  useEffect(() => {
    API.AllProperty().then((response) => {
      let filterProperty = response.data?response.data.slice(0, 6):[];
      setSelectiveProperty(filterProperty);
    });
  }, []);

  const singleProperty = (id) => {
    localStorage.setItem("propertyId", JSON.stringify(id));
    API.FindPropertyById(id).then((response) => {
      callbackProperty(response.data);
      navigate("/single-property");
    });
  };
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Slider1 />
      </div>
      {/* <div className="property">
        <div className="container">
          <h4 className="available-property mt-5">
            <span style={{ color: "rgb(57, 57, 230)" }}>Featured</span>
            &nbsp;Property
          </h4>
          <div className="row mb-4 mt-3">
            {selectiveProperty.length > 0 ? (
              selectiveProperty.map((data) => {
                return (
                  <div
                    className="col-lg-4 col-md-4 col-sm-6 mb-4 mt-3 "
                    key={data.id}
                  >
                    <Link
                      onClick={() => singleProperty(data.id)}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card">
                        <img
                          src={
                            data.property_image
                              ? "http://103.73.191.205:8000/Images/" +
                              data.property_image
                              : "Image/no-image.png"
                          }
                          className="card-img-top rounded-0"
                          alt="property-pic"
                        />
                        <div className="card-img-overlay">
                          <span className="bg-dark rounded-2 text-white pt-2 pb-2 px-4">
                            For {data.property_type}
                          </span>
                        </div>
                        <div className="card-body text-center">
                          <p className="card-text">
                            {data.description}({data.property_area})
                          </p>
                          <p className="text-dartk fs-6">
                            <i className="fa fa-inr mt-2"></i>&nbsp;
                            {data.price}
                          </p>
                        </div>
                        <div className=" card-footer rounded-0 text-center">
                          <i className="fa-solid fa-location-dot text-gray-800 me-2" />
                          {data.locality},{data.city}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <p className="text-center mt-5" style={{ fontSize: "16px" }}>
                No Properties Available
              </p>
            )}
          </div>
        </div>
      </div> */}



      {/* NEW */}

      <div style={{backgroundColor:'white',height:'105px'}}></div>
      <section class="practices" >
    <div class="content-wrapper limited-width">
      <div class="avatar"></div>
      <div class="copy-wrapper">
        <div></div>
        <div class="copy">
          <div class="heading fw-semibold">Explore a wide range of research-backed practices to:</div>
          <ul class="list">
            <li>Stress less</li>
            <li>Sleep better</li>
            <li>Improve energy and focus</li>
            <li>Boost your mood</li>
          </ul>
        </div>

      </div>
    </div>
  </section>

  <div style={{backgroundColor:'white',height:'105px'}}></div>


      {/* OUR VISION */}

      <div className="our-vision text-black">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 column">
              <div className="card">
                <i
                  className="fa fa-rocket  vision-icon mx-auto d-block"
                  aria-hidden="true"
                ></i>
                <h5 className="card-title fw-bold">Mission</h5>
                <p className="card-text">
                  {/* We possess a well-maintained databank of residential and
                  commercial properties to be bought, sold and rented. All of
                  these properties are located at the best of the locations that
                  would be conducive for prolific business as well as a peaceful
                  stay. In addition to all these, we have provided the following
                  shopping complexes to our clients on rent basis.  */}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci exercitationem magnam obcaecati voluptatibus veritatis magni a minima veniam at consequuntur similique incidunt totam corrupti nam illum, voluptas cumque tenetur nobis?
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 column">
              <div className="card">
                <i
                  className="fa fa-binoculars vision-icon mx-auto d-block"
                  aria-hidden="true"
                ></i>
                <h5 className="card-title fw-bold">Vision</h5>
                <p className="card-text">
                  {/* We laid the foundation-stone of our firm in 1978 when the real
                  estate industry was not at its height. We have grown with the
                  growth of the industry over the years and have seen the
                  tortuous path which has led us to the apex of success in the
                  present scenario. */}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci exercitationem magnam obcaecati voluptatibus veritatis magni a minima veniam at consequuntur similique incidunt totam corrupti nam illum, voluptas cumque tenetur nobis?
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 column values-col">
              <div className="card">
                <i
                  className="fa fa-life-ring  vision-icon mx-auto d-block"
                  aria-hidden="true"
                ></i>
                <h5 className="card-title fw-bold">Values</h5>
                <p className="card-text">
                  {/* Having an exposure to the real estate industry for more than 3
                  decades now, we have gained an insight into the various
                  aspects of the same. We keep our self abreast with the
                  changing phases of the industry so that we can cater to the
                  particular demands of the clients. */}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci exercitationem magnam obcaecati voluptatibus veritatis magni a minima veniam at consequuntur similique incidunt totam corrupti nam illum, voluptas cumque tenetur nobis?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OUR SERVICE */}



      {/* <div className="our-service ">
        <div className="container">
          <div className="row">
            <h3 className="service-head">
              <span style={{ color: "rgb(57, 57, 230)" }}>Our</span> Services
            </h3>
            <div className="col-md-4 service-card">
              <Link to="/real-estate-agent" className="service-link">
                <div className="card">
                  <div className="header">
                    <img src="Image/selling.jpg" alt="agent" />
                    <h5 className="card-title me-5 mt-4">Real estate agent</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      Today we are renowned as a potential real estate agent in
                      Varanasi and the surrounding areas for encompassing the
                      best of the localities therein for residential..
                    </p>
                    <p className=" mt-2 text-primary">Read more</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 service-card">
              <Link to="buying-property" className="service-link">
                <div className="card">
                  <div className="header">
                    <img src="Image/selling.jpg" alt="agent" />
                    <h5 className="card-title me-5 mt-4">Buying Property</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      Dreamlands Property Dealer is a well-renowned Real Estate
                      Agent, located in Uttar Pradesh. The company is known for
                      offering expert and reliable Buying Property...
                    </p>
                    <p className=" mt-2 text-primary">Read more</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 service-card">
              <Link to="sell-property" className="service-link">
                <div className="card">
                  <div className="header">
                    <img src="Image/selling.jpg" alt="agent" />
                    <h5 className="card-title me-5 mt-4">Sell Property</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      Dreamlands Property Dealer is a respected and dependable
                      real estate agent in Uttar Pradesh that offers excellent
                      properties to first time buyers customers at...
                    </p>
                    <p className=" mt-2 text-primary">Read more</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div> */}


      {/* About Dreamland property */}

      {/* <div className="about-container">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-6  about-rl"
            
            >
              <h5 className="dreamland-head">
                <Typewriter
                  options={{
                    strings: ["About Dreamlands Property"],
                    autoStart: true,
                    loop: true
                  }}
                />
              </h5>
              <p className="dreamland-para">
                The Holy City-Varanasi has been the tourists’ destination and
                pilgrims’ resort since time immemorial. With changing times, the
                demand for residential and commercial properties herein has
                increased manifolds. We, Dreamlands Property Dealer, stand for
                the solution of all your problems regarding buying, selling and
                rent of such properties. Embarking upon an indomitable
                experience of many years, we carve out solutions so that they
                put an end to all your requisites in a dream house or a prolific
                investment in the real estate sector. Moreover, our team members
                are extremely dedicated towards the complete satisfaction of our
                clients and suggest the best properties to them every time.
              </p>
              <Link to="/about">
                <button
                  type="button"
                  className="btn btn-danger rounded-3 pt-2 mt-3 mb-3"
                >
                  Read more
                </button>
              </Link>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <img
                  src="Image/about-photo.jpg"
                  alt="about-pic"
                  className="mt-5 mb-5"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Testimonial */}

      <div className="testimonial-container">
        <div className="container">
          <h5 className="testimonial-head mt-4">
            <span style={{ color: "rgb(57, 57, 230)" }}>Testi</span>
            monials
          </h5>
          <div className="row mt-4 testimonial-row">
            {approvedTestimonial.map((data) => {
              return (
                <div
                  className=" col-lg-3 col-md-6 col-sm-6 testimonial-col"
                  key={data.id}
                >
                  <div className="card">
                    <i
                      className="fa fa-quote-left quotes-icon w-25 mx-auto d-block "
                      aria-hidden="true"
                    ></i>
                    <p className="card-text">{data.review.substr(0, 190)}</p>
                    <Link to="/testimonial" className=" read-more">
                      Read More
                    </Link>
                    <p className="mt-3 fw-bold">{data.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Enquiry form */}

      <div className=" container-fluid  g-0 enquiry-bg ">
        <div className="container enquiry-container text-dark">
          <div className="row">
            <div className="col-md-7">
              <Enquiry />
            </div>
            <div className="col-md-5">
              <img
                src="Image/enquiry.jpg"
                alt="enquiry-pic"
                className="w-100 h-100 card-image "
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
