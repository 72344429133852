import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import { API } from "../../../API/RealEstate_API";
import { ToastContainer, toast } from "react-toastify";

const AllContact = () => {
  const [contact, setContact] = useState([]);

  useEffect(() => {
    API.GetContact().then((response) => {
      if (response.success === true) {
        setContact(response.data);
      }
    });
  });

  const removeContact = (id) => {
    API.RemoveContact(id).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
      }
    });
    API.GetContact().then((response) => {
      if (response.data.length >0) {
        setContact(response.data);
      }
      else{
        setContact([]);
      }
    });
  };
  return (
    <div className="row">
      <ToastContainer position="top-center" theme="light" />
      <h4 style={{ textAlign: "center" }}>All Contact</h4>
      {contact.length > 0 ? (
        contact.map((item) => {
          return (
            <div className="col-xl-6 col-md-6 mb-2 mt-3" key={item.contact_id}>
              <div
                className="card p-2 mb-2 rounded-0"
                style={{
                  backgroundColor: "rgb(245, 236, 219)",
                  display: "flex",
                }}
              >
                <div className="mx-3">
                  <p className="fs-6">
                    Contact by <b className="text-dark">{item.name}</b>
                  </p>
                  <span>Email: {item.email}</span>
                  <br />
                  <span>Phone: {item.phone}</span>
                  <p>{item.details}</p>
                </div>
                <div className="">
                  <button
                    className="btn btn-danger btn-sm mx-auto d-block"
                    onClick={() => removeContact(item.contact_id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-center mt-5" style={{ fontSize: "16px" }}>
          No Contact Available
        </p>
      )}
    </div>
  );
};

export default Dashboard(AllContact);
