import React from "react";
import "./PostProperty.css";
import Form from "./Form";
import PropertyByCity from "../PropertyByCity/PropertyByCity";

function PostProperty() {
  return (
    <div className="about-page-container">
      <div className="container">
        <div className="row ">
          <div className="col-md-8 mt-5 ">
            <Form />
          </div>
          <PropertyByCity />
        </div>
      </div>
    </div>
  );
}

export default PostProperty;
