import React from "react";
import "../About/About.css";
import Enquery from "./Enquery";
import PropertyByCity from "../PropertyByCity/PropertyByCity";

function SellProperty() {
  return (
    <div className="about-page-container">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mt-5">
            <h5 className="about-page-head">Selling Property</h5>
            <img src="Image/about-page.avif" alt="About-us" className="w-50 about-image" />
            <p style={{ textAlign: "justify", opacity: "0.9",marginBottom:"150px",fontSize:"15px" }}>
              Our experts search for listings of sale properties in Uttar
              Pradesh. We offer outstanding and well-connected properties to the
              customers. The dream of every Indian is to buy a dream house and
              it is fulfilled by our organization. We assist our trustworthy
              customers in selecting a lot of properties. We are also showing
              all the latest sale properties iand also assist them in
              negotiating a good deal for our customers.
            </p>
            <Enquery/>
          </div>
          <PropertyByCity />
        </div>
      </div>
    </div>
  );
}

export default SellProperty;
