import React, { useState } from "react";
import "./Login.css";
import { API } from "../../API/RealEstate_API";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typewriter from "typewriter-effect";

export default function Login() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const onChangeData = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const onLogin = (e) => {
    e.preventDefault();
    API.Login(loginData).then((response) => {
      if (response.success === false) {
        toast.error(response.message);
      }
      if (response.success === true) {
        localStorage.setItem("authType", JSON.stringify(response.success));
        navigate("/admin-home");
      }
    });
  };
  return (
    <>
      <ToastContainer position="top-center" theme="colored" autoClose={3000} />
      <div className="login-form">
      <div className="container">
        <div className="row ">
          <div className="col-md-12">
              <div className="auth-wrapper">
                <h4 className="text-center fs-2" style={{ fontStyle: "italic" }}>
                  <Typewriter
                    options={{
                      strings: ["Welcome to Login Page"],
                      autoStart: true,
                      loop: true
                    }}
                  />
                </h4>
                <div className="auth-inner">
                {/* <h4>Enter Details For Sign In</h4> */}
                  <form onSubmit={onLogin}>
                    <div className="mb-3">
                      <label className="text-success fw-bold">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={loginData.email}
                        className="form-control"
                        placeholder="Enter email"
                        onChange={onChangeData}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="text-success fw-bold">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={loginData.password}
                        className="form-control"
                        placeholder="Enter password"
                        onChange={onChangeData}
                      />
                    </div>
                    <div className="d-grid mt-5">
                      <button type="submit" className="btn btn-primary fw-bold">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
