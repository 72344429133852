import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import "../Dashboard.css";
import { API } from "../../../API/RealEstate_API";
import { ToastContainer, toast } from "react-toastify";

function AdminService() {
  const [about, setAbout] = useState("");

  useEffect(() => {
    API.GetServices().then((response) => {
    //   alert(JSON.stringify(response.data[0]));
      setAbout(response.data?response.data[0].services_text:"");
  })

    // tmp()

  }, []);



const UpdateAbout=(e)=>{
  setAbout(e.target.value)
}

const PostUpdate=()=>{
  // alert(about)
  let data={
    "text":about
  }
  API.UpdateServices(data).then((response) => {
    // alert(JSON.stringify(response))
    if (response.success === true) {
        toast.success(response.message);
    }
})
}

  return (
    <div className="row mt-5">
      <ToastContainer position="top-center" theme="light" />
      <h4 style={{ textAlign: "center" }}>Services</h4>
      <div className="col-md-1"></div>
          <div className="col-md-10 mb-4 text-center mt-3" >
<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Edit Services</label>
  <textarea class="form-control" id="exampleFormControlTextarea1 dataAbout" rows="12" value={about} onChange={UpdateAbout}></textarea>
</div>

<button className="btn btn-primary" onClick={()=>PostUpdate()}>Submit</button>
          </div>
    
    </div>
  );
}

export default Dashboard(AdminService);
