const baseUrl = "http://103.73.191.205:8000/api";

export const API = {
  // Admin login
  Login: async (data) => {
    try {
      const response = await fetch(baseUrl + "/login/admin-login", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  //Add property by admin
  AddProperty: async (data) => {
    const formdata = new FormData();
    formdata.append("property_type", data.property_type);
    formdata.append("property_category", data.property_category);
    formdata.append("property_area", data.property_area);
    formdata.append("price", data.price);
    formdata.append("property_image", data.property_image);
    formdata.append("city", data.city);
    formdata.append("state", data.state);
    formdata.append("locality", data.locality);
    formdata.append("description", data.description);
    try {
      const response = await fetch(baseUrl + "/add/add-property", {
        method: "post",
        body: formdata,
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  //Search all property 
  AllProperty: async () => {
    try {
      const response = await fetch(baseUrl + "/property/all-property", {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
// user search property
  SearchProperty: async (data) => {
    try {
      const response = await fetch(
        baseUrl +
          `/property/search-property?property_type=${data.property_type}&property_category=${data.property_category}&city=${data.city}&locality=${data.locality}&start_budget=${data.start_budget}&end_budget=${data.end_budget}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
//find property by id
  FindPropertyById: async (id) => {
    try {
      const response = await fetch(baseUrl + `/property/find-property/${id}`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
//Delete property by id by admin
  DeletePropertyById: async (id) => {
    try {
      const response = await fetch(
        baseUrl + `/property/delete-property/${id}`,
        {
          method: "delete",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  AddTestimonial: async (data) => {
    try {
      const response = await fetch(baseUrl + "/testimonial/add-testimonial", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetPendingTestimonial: async () => {
    try {
      const response = await fetch(
        baseUrl + "/testimonial/pending-testimonial",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetApprovedTestimonial: async () => {
    try {
      const response = await fetch(
        baseUrl + "/testimonial/approved-testimonial",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetRejectTestimonial: async () => {
    try {
      const response = await fetch(
        baseUrl + "/testimonial/reject-testimonial",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  UpdateTestimonial: async (data) => {
    console.log(data);
    try {
      const response = await fetch(
        baseUrl + `/testimonial/update-testimonial`,
        {
          method: "put",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  PostProperty: async (data) => {
    const formdata = new FormData();
    formdata.append("property_for", data.property_type);
    formdata.append("property_category", data.property_category);
    formdata.append("property_area", data.area);
    formdata.append("price", data.price);
    formdata.append("property_image", data.property_image);
    formdata.append("description", data.description);
    formdata.append("city", data.city);
    formdata.append("state", data.state);
    formdata.append("locality", data.locality);
    formdata.append("user_name", data.user_name);
    formdata.append("user_email", data.user_email);
    formdata.append("user_address", data.user_address);
    formdata.append("user_city", data.user_city);
    formdata.append("user_state", data.user_state);
    formdata.append("user_phone", data.user_phone);

    try {
      const response = await fetch(baseUrl + "/user-property/post-property", {
        method: "post",
        body: formdata,
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  GetPendingUserProperty: async () => {
    try {
      const response = await fetch(
        baseUrl + "/user-property/pending-user-property",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetApprovedUserProperty: async () => {
    try {
      const response = await fetch(
        baseUrl + "/user-property/approved-user-property",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  UpdateUserProperty: async (id) => {
    try {
      const response = await fetch(
        baseUrl + `/user-property/update-user-property/${id}`,
        {
          method: "put"
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  AddEnquiry:async(data) =>{
    try{
      const response = await fetch(baseUrl + "/enquiry/add-enquiry", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    }
    catch(error){
      return error;
    }
  },

  GetEnquiry: async () => {
    try {
      const response = await fetch(
        baseUrl + "/enquiry/all-enquiry",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  RemoveEnquiry: async (id) => {
    try {
      const response = await fetch(
        baseUrl + `/enquiry/remove-enquiry/${id}`,
        {
          method: "delete",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  GetAbout: async () => {
    try {
      const response = await fetch(
        baseUrl + "/enquiry/about",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  UpdateAbout:async(data) =>{
    try{
      const response = await fetch(baseUrl + "/enquiry/update-about", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    }
    catch(error){
      return error;
    }
  },

  GetServices: async () => {
    try {
      const response = await fetch(
        baseUrl + "/enquiry/services",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  UpdateServices:async(data) =>{
    try{
      const response = await fetch(baseUrl + "/enquiry/update-services", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    }
    catch(error){
      return error;
    }
  },

  // add contact from user
  AddContact: async (data) => {
    try {
      const response = await fetch(baseUrl + "/contact/add-contact", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetContact: async () => {
    try {
      const response = await fetch(
        baseUrl + "/contact/all-contact",
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  RemoveContact: async (id) => {
    try {
      const response = await fetch(
        baseUrl + `/contact/remove-contact/${id}`,
        {
          method: "delete",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  // property enquiry
  AddPropertyEnquiry:async(data) =>{
    try{
      const response = await fetch(baseUrl + "/property_enquiry/add-property-enquiry", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    }
    catch(error){
      return error;
    }
  },

};
