import React, { useState } from "react";
import "../Dashboard.css";
import Dashboard from "../Dashboard";
import { API } from "../../../../src/API/RealEstate_API";
import { ToastContainer, toast } from "react-toastify";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBRadio,
  MDBTextArea,
  MDBFile,
} from "mdb-react-ui-kit";

function Add() {
  const [property, setProperty] = useState({
    property_type: "",
    property_category: "",
    property_area: "",
    price: "",
    property_image: "",
    city: "",
    state: "",
    locality: "",
    description: "",
  });

  const handleChange = (e) => {
    if (e.target.files) {
      setProperty({ ...property, [e.target.name]: e.target.files[0] });
    } else {
      setProperty({ ...property, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.AddProperty(property).then((response) => {
      // console.log(response);
      toast(response.message);
    });
    setProperty({
      property_type: "",
      property_category: "",
      property_area: "",
      price: "",
      property_image: "",
      city: "",
      state: "",
      locality: "",
      description: "",
    });
  };
  return (
    <MDBRow className="d-flex justify-content-center align-items-center h-100 property-form">
      <ToastContainer position="top-center" theme="light" />
      <MDBCol>
        <MDBCard className="">
          <MDBRow className="g-0">
            <MDBCol lg="5" md="12" className=" d-md-block">
              <MDBCardImage
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp"
                alt="Sample photo"
                className="rounded-start"
                fluid
              />
            </MDBCol>

            <MDBCol lg="7" md="12" className="mt-5">
              <h3 className="text-uppercase fw-bold text-center">
                Property Details
              </h3>
              <form onSubmit={handleSubmit}>
                <MDBCardBody className="text-black d-flex flex-column justify-content-center">
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="d-md-flex ustify-content-start align-items-center mt-2">
                        <label htmlFor="property_type" className="me-3">
                          Property For:
                        </label>
                        <MDBRadio
                          name="property_type"
                          id="sell"
                          value="sell"
                          label="Sell"
                          onChange={handleChange}
                          inline
                          required
                        />
                        <MDBRadio
                          name="property_type"
                          id="rent"
                          value="rent"
                          label="Rent"
                          onChange={handleChange}
                          inline
                          required
                        />
                      </div>
                    </MDBCol>

                    <MDBCol md="6">
                      <select
                        className="form-control mt-2"
                        value={property.property_category}
                        name="property_category"
                        onChange={handleChange}
                        required
                      >
                        <option>--Select--</option>
                        <option value="residential">
                          Residential Property
                        </option>
                        <option value="commercial">Commercial Property</option>
                        <option value="showrooms">Showrooms</option>
                        <option value="industrial">Industrial Land</option>
                      </select>
                      <label htmlFor="prperty-category">
                        Property Category
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-2">
                    <MDBCol md="6">
                      <MDBInput
                        wrapperclass="mb-4 mt-3"
                        label="Property Area"
                        value={property.property_area}
                        name="property_area"
                        onChange={handleChange}
                        size="lg"
                        id="form1"
                        type="text"
                        required
                      />
                    </MDBCol>

                    <MDBCol md="6">
                      <MDBInput
                        wrapperclass="mb-4 mt-3"
                        label="Property Price"
                        value={property.price}
                        name="price"
                        onChange={handleChange}
                        size="lg"
                        id="form2"
                        type="text"
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-2">
                    <MDBCol md="6">
                      <MDBInput
                        wrapperclass="mb-4"
                        onChange={handleChange}
                        value={property.city}
                        name="city"
                        label="City"
                        size="lg"
                        id="form3"
                        type="text"
                        required
                      />
                    </MDBCol>

                    <MDBCol md="6">
                      <MDBInput
                        wrapperclass="mb-4"
                        label="State"
                        value={property.state}
                        onChange={handleChange}
                        name="state"
                        size="lg"
                        id="form4"
                        type="text"
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-3 mb-3">
                    <MDBCol md="6">
                      <MDBInput
                        wrapperclass="mb-4"
                        label="Locality"
                        value={property.locality}
                        onChange={handleChange}
                        name="locality"
                        size="lg"
                        id="form5"
                        type="text"
                        required
                      />
                    </MDBCol>

                    <MDBCol md="6">
                      <MDBFile
                        wrapperclass="mb-5"
                        name="property_image"
                        onChange={handleChange}
                        size="lg"
                        id="form7"
                        type="file"
                        required
                      />
                      <span className="fw-bold mb-0 me-4">Image</span>
                    </MDBCol>
                  </MDBRow>
                  <MDBTextArea
                    wrapperclass=""
                    label="Description"
                    value={property.description}
                    onChange={handleChange}
                    name="description"
                    size="lg"
                    id="form6"
                    type="text"
                    required
                  />
                  <div className="d-flex justify-content-center pt-3">
                    <button type="submit" className="btn btn-warning">
                      Submit
                    </button>
                  </div>
                </MDBCardBody>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}

export default Dashboard(Add);
