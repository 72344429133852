import React, { useEffect, useState } from 'react';
import PropertyByCity from './PropertyByCity';
import { API } from '../../API/RealEstate_API';
import "./style.css";

function Mirzapur() {

    const [mirzapurproperty, setMirzapurProperty] = useState([]);

  useEffect(() => {
    API.AllProperty().then((response) => {
      const filterData = response.data.filter(
        (item) => item.city.toLowerCase() === "mirzapur"
      );
      setMirzapurProperty(filterData);
    });
  }, []);
  return (
    <div className="about-page-container">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mt-5">
          <h4>
          <span style={{ color: "rgb(57, 57, 230)" }}>Properties</span>
            &nbsp;in Mirzapur
          </h4>
            <p style={{ textAlign: "justify", opacity: "0.9" }}>
              We have grown with the growth of the industry over the years and
              have seen the tortuous path which has led us to the apex of
              success in the present scenario. Varanasi, having risen as a
              commercially active place, has become the target for many business
              entrepreneurs. This has boosted the demand for commercial
              complexes, showrooms and office spaces herein. We possess the
              following attributes for which we have gained an edge over the
              other players in the market: Experience: Having an exposure to the
              real estate industry for more than 3 decades now, we have gained
              an insight into the various aspects of the same. We keep our self
              abreast with the changing phases of the industry so that we can
              cater to the particular demands of the clients. Moreover, our team
              members are extremely dedicated towards the complete satisfaction
              of our clients and suggest the best properties to them every time.
              Our resource: We possess a well-maintained databank of residential
              and commercial properties to be bought, sold and rented. All of
              these properties are located at the best of the locations that
              would be conducive for prolific business as well as a peaceful
              stay. In addition to all these, we have provided the following
              shopping complexes to our clients on rent basis
            </p>
            <div className="row by-city">
            {mirzapurproperty.map((data) => {
              return (
                <div className="col-xl-4 col-md-6 col-sm-6 mb-3" key={data.id}>
                    <div className="card">
                  <img
                    className="card-img-top"
                    alt="city-property"
                    src={"http://103.73.191.205:8000/Images/" + data.property_image}
                  />
                  <div className="card-body">
                    <div className="card-text">
                        {data.description}{data.property_area}
                    </div>
                  </div>
                  <div className="card-footer">
                  <i className="fa-solid fa-location-dot text-gray-800 me-2" />
                        {data.locality},{data.city}
                  </div>
                </div>
                </div>
              );
            })}
            </div>
          </div>
          <PropertyByCity />
        </div>
      </div>
    </div>
  )
}

export default Mirzapur
