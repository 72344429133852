import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard';
import { API } from "../../../API/RealEstate_API";
import "./UserProperty.css";

function UserApprovedProperty() {
  const [approved,setApproved]=useState([]);

  useEffect(() => {
    API.GetApprovedUserProperty().then((response) => {
      if(response.success===true){
        setApproved(response.data);
      }
    });
  }, []);
  return (
    <div className="row mt-5 user-prop">
      <h4 style={{ textAlign: "center" }}>Approved User Property</h4>
      {approved.length>0? (approved.map((item) => {
        return (
          <div className="col-lg-6 mt-3" key={item.id}>
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src={item.property_image ?
                      "http://103.73.191.205:8000/Images/" + item.property_image
                      :"Image/no-image.png"
                    }
                    className="img-fluid w-100"
                    alt="Images"
                  />
                </div>
                <div className="col-md-8 mt-4">
                  <div className="card-body">
                    <h5 className="card-title">{item.property_category}</h5>
                    <p className="card-text">
                      {item.property_description}
                    </p>
                    <p className="card-text">
                      <small className="text-body-secondary">
                        {item.visitor_city}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })):(<p className="text-center mt-5" style={{fontSize:"16px"}}>No Properties Available</p>)}
    </div>
  )
}

export default Dashboard(UserApprovedProperty);
