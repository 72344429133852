import React from "react";
import "./Project.css";
// import video_path from "../DeliveredProject/ProjectAssets/video_project.mp4";

function CelebrityGreens() {
  return (
    <>
      <div className="project1-container">
        <div className="container-fluid g-0 project-img">
          <img
            src="Image/celebrity-green.jpg"
            alt="celebrity-green"
            className="w-100"
          />
          <div className="overlay right-overlay">
            <div className="text">Premium Apartment</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12">
            <h5
              className="text-center fs-3 mb-5"
              style={{
                fontStyle: "italic",
                opacity: ".9",
                color: "rgb(233, 97, 48)",
              }}
            >
              Dreamland Celebrity Greens
            </h5>
          </div>
        </div>
      </div>
      {/* <div className="overview"> */}
      <div className="container overview">
        <div className="row">
          <div className="col-md-6 mt-5">
            <h4>
              <span style={{ color: "rgb(57, 57, 230)" }}>Over</span>
              view
            </h4>
            <p
              className="mt-3"
              style={{ textAlign: "justify", lineHeight: "1.5rem" }}
            >
              Dreamland Developers are the brains behind Project1, which is all
              set to become the Landmark of this city. This city exudes
              graciously elegant charm, setting it apart from all other cities.
              Project1 is slated to become the best ever in terms of luxurious
              style, elegance and modernity..Spread over an area of 21.45 acres,
              comprising enigmatic towers with state-of-the-art facilities and
              amenities that meet global standards, Mulberry Heights presents
              the perfect picture of your life amidst a vast green landscape,
              with 80% open spaces, this Golf view facing project has everything
              that a holistic lifestyle requires. A peaceful experience of a
              habitat to security that lets you live without any worry. Mulberry
              Heights is a perfect sanctum for those who are looking for a
              wholesome life experience.
            </p>
          </div>
          {/* <div className="col-md-6 videos">
            <video controls loop autoPlay muted className="w-100">
              <source src={video_path} type="video/mp4" />
            </video>
          </div> */}
        </div>
      </div>
      <div className="container facility mt-5">
        <div className="row">
          <h4>
            {" "}
            <span style={{ color: "rgb(57, 57, 230)" }}>Facil</span>ities
          </h4>
          <div className="col-md-6 mb-3 mt-2">
            <div className="icon">
              <img src="Image/facility1.jpg" alt="medanta" className="w-100" />
              <div className="overlay1 left-overlay">
                <div className="text fs-1">Facility 1</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Facility 1</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Facility 1</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Facility 1</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Facility 1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-container mt-5">
        <div className="container">
          <h4>
            {" "}
            <span style={{ color: "rgb(57, 57, 230)" }}>Features&nbsp;&</span>
            &nbsp;Specifications
          </h4>
          <div className="row mt-5">
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/club.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>CLUB</h5>
                  <p>House High End Club house with modern facilities</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/yoga.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>YOGA CENTER</h5>
                  <p>Bend your mind, inspire yourself in Yoga Center</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/swimming.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>SWIMMING POOL</h5>
                  <p>Swimming Pool with separate kids Pool</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/gym.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>GYM</h5>
                  <p>GYM with all facilities and equipment</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/cctv.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>CCTV SURVEILLANCE</h5>
                  <p>
                    Gated Community, Access Control at entrance, CCTV
                    surveillance
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/water.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>24 X 7 WATER SUPPLY</h5>
                  <p>get 24 X 7 water supply in your home</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/parking.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>PARKING SPACE</h5>
                  <p>Ample Car Parking Space</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/power.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>POWER BACK-UP</h5>
                  <p>Power Back-up (For Common Areas)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container project-feature mt-5 mb-5">
        <div className="row">
          <h4>
            {" "}
            <span style={{ color: "rgb(57, 57, 230)" }}>Project</span>
            &nbsp;Structure
          </h4>
          <div className="col-md-6 mb-3">
            <div className="icon">
              <img src="Image/facility1.jpg" alt="medanta" className="w-100" />
              <div className="overlay1 left-overlay">
                <div className="text fs-1">Project Structure</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Project Structure</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Project Structure</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Project Structure</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                    <div className="text fs-2">Project Structure</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CelebrityGreens;
