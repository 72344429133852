import React from "react";
import "./Activity.css";

function Activity() {
  return (
    <>
                <div className="">
          <section>
        <div className="item">
                <img src="Image/celebrity-green.jpg" alt="images not found" />
                <div className="cover">
                  <div className="container">
                    <div className="header-content" style={{alignSelf:'center'}}>
                      {/* <div className="line" /> */}
                      {/* <h1>Realize all your reality needs</h1> */}
                      <h1 style={{textAlign:'center',}}>Road to Knowledge</h1>
                      {/* <h4>
                        Life is not always perfect. Like a road ,it has many bends, ups, and downs, but that's it's beauty
                      </h4> */}
                      <h2 style={{textAlign:'center',textDecoration:'underline'}}>Activity</h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

      </div>
      {/* <div className="project1-container">
        <div className="container-fluid g-0 project-img">
          <img src="Image/project1.jpg" alt="mulberry-heights" className="w-100" />
          <div className="overlay right-overlay">
            <div className="text">Premium Apartment</div>
          </div>
        </div>
      </div> */}


      {/* <div className="container">
        <div className="row mt-5">
          <div className="col-md-12">
            <h5
              className="text-center fs-3 mb-5"
              style={{ fontStyle: "italic", opacity: ".9",color: "rgb(233, 97, 48)" }}
            >
              Dreamland Mulberry Heights
            </h5>
          </div>
        </div>
      </div> */}



      {/* <div className="overview">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
            <h4 style={{color:'black'}}>
                Activity
              
              </h4>
              <p className="mt-3" style={{textAlign:"justify",lineHeight:"1.5rem"}}>
                Services include both online and offline.
              </p>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className="container facility mt-5">
        <div className="row">
        <h4> <span style={{ color: "rgb(57, 57, 230)"}}>
              Facil
            </span>ities</h4>
          <div className="col-md-6 mb-3 mt-2">
            <div className="icon">
              <img src="Image/facility1.jpg" alt="medanta" className="w-100" />
              <div className="overlay1 left-overlay">
                <div className="text fs-1">Facility 1</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility2.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                <div className="text fs-1">Facility 1</div>
              </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                <div className="text fs-1">Facility 1</div>
              </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility2.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                <div className="text fs-1">Facility 1</div>
              </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                  <div className="overlay1 left-overlay">
                <div className="text fs-1">Facility 1</div>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}



      <div className="features-new mt-5">
        <div className="container">
        {/* <h4> <span style={{ color: "rgb(57, 57, 230)"}}>
              Features&nbsp;&
            </span>&nbsp;Specifications</h4> */}
            <h2 className="about-page-head">Activity</h2>
            {/* <h4 style={{color:'black'}}>Activity</h4> */}
          <div className="row mt-5">
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/club.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>CLUB</h5>
                  <p>House High End Club house with modern facilities</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/yoga.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>YOGA CENTER</h5>
                  <p>Bend your mind, inspire yourself in Yoga Center</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/swimming.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>SWIMMING POOL</h5>
                  <p>Swimming Pool with separate kids Pool</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/gym.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>GYM</h5>
                  <p>GYM with all facilities and equipment</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/cctv.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>CCTV SURVEILLANCE</h5>
                  <p>
                    Gated Community, Access Control at entrance, CCTV
                    surveillance
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/water.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>24 X 7 WATER SUPPLY</h5>
                  <p>get 24 X 7 water supply in your home</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/parking.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>PARKING SPACE</h5>
                  <p>Ample Car Parking Space</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="features-main">
                <img src="Image/power.png" alt="club" className="w-25" />
                <div className="content">
                  <h5>POWER BACK-UP</h5>
                  <p>Power Back-up (For Common Areas)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container project-feature mt-5 mb-5">
        <div className="row">
        <h4> <span style={{ color: "rgb(57, 57, 230)"}}>
              Project
            </span>&nbsp;Structure</h4>
          <div className="col-md-6 mb-3">
            <div className="icon">
              <img src="Image/facility1.jpg" alt="medanta" className="w-100" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-1">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility2.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility2.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="icon">
                  <img
                    src="Image/facility1.jpg"
                    alt="medanta"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Activity;
