import React from "react";
import "../About/About.css";
import Enquery from "./Enquery";
import PropertyByCity from "../PropertyByCity/PropertyByCity";

function BuyingProperty() {
  
  return (
    <div className="about-page-container">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mt-5">
            <h5 className="about-page-head">Buying Property</h5>
            <img src="Image/about-page.avif" alt="About-us" className="w-50 about-image" />
            <p style={{ textAlign: "justify",opacity:"0.9",marginBottom:"100px" ,fontSize:"14px"}}>
              The company is known for offering expert and reliable Buying
              Property Services. If you want to buy the home of your dreams or
              you are looking for a great office space, contact our team of
              professionals. They are the experts of real estate business and
              are committed to offer the best options to you, as per your budget
              and other requirements. Our company deals in all kinds of
              Residential Properties, Commercial Properties and Industrial
              Properties. Trust us to crack the best deal and invest in the best
              property.
            </p>

            <Enquery/>
          </div>
          <PropertyByCity />
        </div>
      </div>
    </div>
  );
}

export default BuyingProperty;
