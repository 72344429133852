import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { API } from "./API/RealEstate_API";
import Navbar from "./components/Header/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Login from "./components/AdminLogin/Login";
import Popup from "./components/Popup/Popup";
import Testimonial from "./components/Testimonial/Testimonial";
import FindProperty from "./components/FindProperty/FindProperty";
import Contact from "./components/Contact/Contact";
import MulberryHeights from "./components/Projects/DeliveredProject/MulberryHeights";
import CelebrityGreens from "./components/Projects/DeliveredProject/CelebrityGreens";
import MulberryVillas from "./components/Projects/DeliveredProject/MulberryVillas";
import AdminHome from "./components/AdminDashboard/pages/AdminHome";
import { useEffect, useState } from "react";
import RealStateAgent from "./components/Services/RealStateAgent";
import BuyingProperty from "./components/Services/BuyingProperty";
import SellProperty from "./components/Services/SellProperty";
import AllProperty from "./components/AdminDashboard/pages/AllProperty";
import Add from "./components/AdminDashboard/pages/Add";
import PendingTestimonial from "./components/AdminDashboard/pages/PendingTestimonial";
import SingleProperty from "./components/SingleProperty/SingleProperty";
import ApprovedTestimonial from "./components/AdminDashboard/pages/ApprovedTestimonial";
import RejectTestimonial from "./components/AdminDashboard/pages/RejectTestimonial";
import PostProperty from "./components/PostProperty/PostProperty";
import PageNotFound from "./components/404 page/PageNotFound";
import ScrollTop from "./components/ScrollButton/ScrollTop";
import Varanasi from "./components/PropertyByCity/Varanasi";
import Mirzapur from "./components/PropertyByCity/Mirzapur";
import Chandauli from "./components/PropertyByCity/Chandauli";
import UserPendingProperty from "./components/AdminDashboard/pages/UserPendingProperty";
import UserApprovedProperty from "./components/AdminDashboard/pages/UserApprovedProperty";
import Thanks from "./components/Thanks/Thanks";
import AllEnquiry from "./components/AdminDashboard/pages/AllEnquiry";
import AllContact from "./components/AdminDashboard/pages/AllContact";
import Gallery from "./components/Gallery/Gallery";
import Services from "./components/Services1/Services";
import Activity from "./components/Activity/Activity";
import Packages from "./components/Packages/Packages";
import AdminAbout from "./components/AdminDashboard/pages/AdminAbout";
import AdminService from "./components/AdminDashboard/pages/AdminService";

function App() {
  const { pathname } = useLocation();

  const [authType, setAuthType] = useState(false);
  const [singleProperty, setSingleProperty] = useState([]);
  const [approvedTestimonial, setApprovedTestimonial] = useState([]);

  const callbackProperty = (data) => {
    setSingleProperty(data);
  };

  useEffect(() => {
    setInterval(() => {
      const auth = localStorage.getItem("authType");
      const user = JSON.parse(auth);
      setAuthType(user);
    });
    API.FindPropertyById(JSON.parse(localStorage.getItem("propertyId"))).then(
      (response) => {
        setSingleProperty(response.data);
      }
    );
    API.GetApprovedTestimonial().then((response) => {
      if (response.data) {
      if (response.data.length > 0) {
        var filterTest = response.data.slice(0, 4);
        setApprovedTestimonial(filterTest);
      }
    }

    });
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    <>
      {authType !== true && (
        <>
          <Navbar />
          <Popup />
        </>
      )}
      <ScrollTop />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              approvedTestimonial={approvedTestimonial}
              callbackProperty={callbackProperty}
            />
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/admin-login" element={authType === true ? <Navigate to="/admin-home" /> : <Login />} />
        <Route
          path="/testimonial"
          element={<Testimonial approvedTestimonial={approvedTestimonial} />}
        />
        <Route
          path="/all-property"
          element={<FindProperty callbackProperty={callbackProperty} />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mulberry-heights" element={<MulberryHeights />} />
        <Route path="/celebrity-greens" element={<CelebrityGreens />} />
        <Route path="/mulberry-villas" element={<MulberryVillas />} />
        <Route path="/post-property" element={<PostProperty />} />
        <Route path="/admin-home" element={<AdminHome />} />
        <Route path="/admin-about" element={<AdminAbout />} />
        <Route path="/admin-services" element={<AdminService />} />
        <Route path="/add-property" element={<Add />} />
        <Route path="/property" element={<AllProperty />} />
        <Route path="/pending-testimonial" element={<PendingTestimonial />} />
        <Route path="/approved-testimonial" element={<ApprovedTestimonial />} />
        <Route path="/pending-user-property" element={<UserPendingProperty />} />
        <Route path="/approved-user-property" element={<UserApprovedProperty />} />
        <Route path="/reject-testimonial" element={<RejectTestimonial />} />
        <Route path="/enquiry" element={<AllEnquiry />} />
        <Route path="/user-contact" element={<AllContact />} />
        <Route path="/real-estate-agent" element={<RealStateAgent />} />
        <Route path="/buying-property" element={<BuyingProperty />} />
        <Route path="/sell-property" element={<SellProperty />} />
        <Route
          path="/single-property"
          element={<SingleProperty singleProperty={singleProperty} callbackProperty={callbackProperty} />}
        />
        <Route path="/varanasi-property" element={<Varanasi />} />
        <Route path="/chandauli-property" element={<Chandauli />} />
        <Route path="/mirzapur-property" element={<Mirzapur />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {authType !== true && <Footer />}
    </>
  );
}
export default App;
