import React from "react";
import "./Slider1.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Slider1() {
  return (
      <div className="row" style={{backgroundColor:'white'}}>
        <div className="col-md-12 g-0">
          <header>
            <OwlCarousel items={1} margin={10} autoplay={true} loop={true}>
              <div className="item">
                <img src="Image/celebrity-green.jpg" alt="images not found" />
                <div className="cover">
                  <div className="container">
                    <div className="header-content">
                      <div className="line" />
                      {/* <h1>Realize all your reality needs</h1> */}
                      <h1>Road to Knowledge</h1>
                      <h4>
                        Life is not always perfect. Like a road ,it has many bends, ups, and downs, but that's it's beauty
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src="Image/project1.jpg" alt="images not found" />
                <div className="cover">
                  <div className="container">
                    <div className="header-content">
                      <div className="line animated bounceInLeft" />
                      {/* <h1>The Way to Fulfill Your Dream</h1> */}
                      <h1>Change your life</h1>
                      <h4>
                      {/* Grab Endless Opportunities on Residential and Commerical Property! */}
                      One wellbeing goal at a time
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src="Image/image4.jpg" alt="images not found" />
                <div className="cover">
                  <div className="container">
                    <div className="header-content">
                      <div className="line animated bounceInLeft" />
                      <h1>Experience Excellence</h1>
                      <h4>
                      Healthy Life Style 
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </header>
        </div>
      </div>
  );
}

export default Slider1;
