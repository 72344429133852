import React, { useEffect, useState } from "react";
import { API } from "../../API/RealEstate_API";
import "./FindProperty.css";
import "../Home/Home.css"
import { Link, useNavigate } from "react-router-dom";
import data from "../customData/selectData.json";
import category from "../customData/category.json";

function FindProperty({ callbackProperty }) {
  const navigate = useNavigate();
  const [allProperty, setAllProperty] = useState([]);
  const [searchProperty, setSearchProperty] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [searchData, setSearchData] = useState({
    property_type: "",
    property_category: "",
    city: "",
    locality: "",
    start_budget: "",
    end_budget: "",
  });

  const onSearchDataChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const onSearchDataSubmit = (e) => {
    e.preventDefault();
    API.SearchProperty(searchData).then((response) => {
      if (response.success === true) {
        setSearchProperty(response.data);
        setShowMessage(false);
      } else if (response.success === false) {
        setShowMessage(true);
        setSearchProperty(response.data);
      }
    });
  };
  useEffect(() => {
    API.AllProperty().then((response) => {
      setAllProperty(response.data);
    });
  }, []);

  const singleProperty = (id) => {
    localStorage.setItem("propertyId", JSON.stringify(id));
    API.FindPropertyById(id).then((response) => {
      // console.log(response.data)
      callbackProperty(response.data);
      navigate("/single-property");
    });
  };
  return (
    <div className="all-property">
      <div className="container">
        <form onSubmit={onSearchDataSubmit}>
          <div className="row find-p">
            <div className="col-md-2">
              <label htmlFor="property_type">Want to</label>
              <select
                name="property_type"
                id="property_type"
                value={searchData.property_for}
                onChange={onSearchDataChange}
                style={{
                  padding: "8px 2px",
                  border: "1px solid rgba(0,0,0,.2",
                  width: "100%",
                }}
              >
                <option value="select">--select--</option>
                <option value="Buy">Buy</option>
                <option value="Rent">Rent</option>
              </select>
            </div>

            <div className="col-md-3">
              <label htmlFor="property_category">Property Category</label>
              <select
                name="property_category"
                id="property_category"
                value={searchData.property_category}
                onChange={onSearchDataChange}
                style={{
                  padding: "8px 2px",
                  border: "1px solid rgba(0,0,0,.2",
                  width: "100%",
                }}
              >
                {category.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-2">
              <label htmlFor="city">City</label>
              <select
                name="city"
                id="city"
                value={searchData.city}
                onChange={onSearchDataChange}
                style={{
                  padding: "8px 2px",
                  border: "1px solid rgba(0,0,0,.2",
                  width: "100%",
                }}
              >
                {data.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-3">
              <label htmlFor="locality">Locality</label>
              <input
                type="text"
                name="locality"
                id="locality"
                value={searchData.locality}
                onChange={onSearchDataChange}
                style={{
                  padding: "8px 5px",
                  border: "1px solid rgba(0,0,0,.2",
                  width: "100%",
                }}
              />
            </div>
            <div className="col-md-2">
              <button type="submit" className="search-button">
                Search
              </button>
            </div>
          </div>
        </form>

        <div className="row mt-5">
          {searchProperty.length > 0 &&
            searchProperty.map((data) => {
              return (
                <div
                  className="col-xl-3 col-md-4 col-sm-6 mb-4 mt-3"
                  key={data.id}
                >
                  <Link
                    onClick={() => singleProperty(data.id)}
                    key={data.id}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-0" style={{ height: "100%" }}>
                      <img
                        src={data.property_image ?
                          "http://103.73.191.205:8000/Images/" + data.property_image
                          :"Image/no-image.png"
                        }
                        className="card-img-top h-50 rounded-0"
                        alt="property-pic"
                      />
                      <div className="card-img-overlay">
                        <span className="bg-danger text-white pt-2 pb-2 px-4">
                          Property Id: {data.id}
                        </span>
                      </div>
                      <div className="card-body text-center">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <p className="text-sm card-text mb-1">
                              {data.description}({data.property_area})
                            </p>
                            <div className=" card-footer mb-0">
                              <i className="fa fa-inr text-gray-600 me-2" />
                              {data.price}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-center mt-1">
                        <i className="fa fa-location-arrow  me-2" />
                        {data.locality},{data.city}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          {showMessage === true ? (
            <h4 className="text-center mb-5">No properties found</h4>
          ) : (
            searchProperty.length <= 0 &&
            allProperty.length>0 ?(allProperty.map((data) => {
              return (
                <div
                  className="col-xl-3 col-md-4 col-sm-6 mb-4 mt-3 "
                  key={data.id}
                >
                  <Link
                    onClick={() => singleProperty(data.id)}
                    key={data.id}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card rounded-0 border-left-success shadow h-100 ">
                      <img
                        src={data.property_image ?
                          "http://103.73.191.205:8000/Images/" + data.property_image
                          :"Image/no-image.png"
                        }
                        className="card-img-top h-50 rounded-0"
                        alt="property-pic"
                      />
                      <div className="card-img-overlay">
                        <span className="bg-danger text-white pt-2 pb-2 px-4">
                          Property Id: {data.id}
                        </span>
                      </div>
                      <div className="card-body text-center">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-sm font-weight-600 mb-1">
                              {data.description}({data.property_area})
                            </div>
                            <div className="mt-2 text-gray-800">
                              <i className="fa fa-inr text-gray-600 me-2" />
                              {data.price}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-0 card-footer text-center mt-1">
                        <i className="fa fa-location-arrow text-gray-600 me-2" />
                        {data.locality},{data.city}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })):(<p className="text-center mt-5" style={{fontSize:"16px"}}>No Properties Available</p>)
          )}
        </div>
      </div>
    </div>
  );
}

export default FindProperty;
