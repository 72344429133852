import React from 'react';
import "../About/About.css";
import "./Thanks.css";
import PropertyByCity from "../PropertyByCity/PropertyByCity";

function Thanks() {
  return (
    <div className="about-page-container">
      <div className="container">
        <div className="row">
          <div className="col-md-8 enquiry-message">
          <h4 className='enquiry-headings'>Enquiry</h4>
            <h5>
            Thank you for inquiry.
            </h5>
            <h6>
            We have received your business request. Our Sales Team will soon get in touch with you.
            </h6>
          </div>
          <PropertyByCity />
        </div>
      </div>
    </div>
  )
}

export default Thanks;
