import React, {useState } from "react";
import "./Testimonial.css";
import { API } from "../../API/RealEstate_API";
import { FaArrowDown } from "react-icons/fa";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import PropertyByCity from "../PropertyByCity/PropertyByCity";

function Testimonial({approvedTestimonial}) {
  
  const [testimonialData, setTestimonialData] = useState({
    name: "",
    company: "",
    email: "",
    location: "",
    mobile: "",
    rating: "",
    review: "",
  });
  const testimonialChange = (e) => {
    setTestimonialData({ ...testimonialData, [e.target.name]: e.target.value });
  };
  const testimonialSubmit = (e) => {
    e.preventDefault();
    if (!testimonialData.name) {
      document.getElementById("name-m").innerText = "Enter Name";
      document.getElementById("review-m").innerText = "";
      document.getElementById("rating-m").innerText = "";
    } else if (!testimonialData.rating) {
      document.getElementById("rating-m").innerText = "Enter Rating";
      document.getElementById("name-m").innerText = "";
      document.getElementById("review-m").innerText = "";
    } else if (!testimonialData.review) {
      document.getElementById("review-m").innerText = "Enter Review";
      document.getElementById("rating-m").innerText = "";
      document.getElementById("name-m").innerText = "";
    } else if (
      testimonialData.name &&
      testimonialData.rating &&
      testimonialData.review
    ) {
      document.getElementById("name-m").innerText = "";
      document.getElementById("review-m").innerText = "";
      document.getElementById("rating-m").innerText = "";
      API.AddTestimonial(testimonialData).then((response) => {
        if (response.success === true) {
          toast.success(response.message);
        } else {
          toast.danger(response.message);
        }
        setTestimonialData({
          name: "",
          company: "",
          email: "",
          location: "",
          mobile: "",
          rating: "",
          review: "",
        })
      });
    }
  };

  const postTestimonial = () => {
    $("#my-form").slideToggle();
  };
  const cancelClick = () => {
    $("#my-form").slideUp();
  };
  let star=[];
  let num=0;
  const printStar=(count)=>{
    star=[];
    num=0;
    for(let i=0;i<5;i++){
      if(i<count){
        star.push(<i className="fa-solid fa-star"></i>);
        num++;
      }
      else{
        star.push(<i className="fa-regular fa-star"></i>)
      }
    }
  }
  return (
    <>
                <div className="">
          <section>
        <div className="item">
                <img src="Image/celebrity-green.jpg" alt="images not found" />
                <div className="cover">
                  <div className="container">
                    <div className="header-content" style={{alignSelf:'center'}}>
                      {/* <div className="line" /> */}
                      {/* <h1>Realize all your reality needs</h1> */}
                      <h1 style={{textAlign:'center',}}>Road to Knowledge</h1>
                      {/* <h4>
                        Life is not always perfect. Like a road ,it has many bends, ups, and downs, but that's it's beauty
                      </h4> */}
                      <h2 style={{textAlign:'center',textDecoration:'underline'}}>Testimonial</h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

      </div>
      <div className="testimonial-page-container" style={{backgroundColor:" rgb(236, 228, 228)"}}>
        <div className="container">
          <div className="row pb-4">
            <ToastContainer position="top-center" theme="light" />
            <div className="col-md-12">
            {/* <h4 className="mt-5">Testimonials</h4> */}
            <h2 className="about-page-head mt-5">Testimonials</h2>
              {approvedTestimonial.map((test) => {
                return (
                  <div className="card review-card mt-3" key={test.id}>
                    <p className="font-weight-bold">Review by {test.name}</p>
                    <p style={{fontWeight:"00px",fontSize:"11px"
                  }}>
                      {printStar(test.rating)}
                      {star.map((item,index)=>{
                        return <span key={index}>{item}</span>
                      })}&nbsp;&nbsp;{num}/5
                      </p>
                    <p
                    style={{fontWeight:"00px",fontSize:"13px"
                  }}
                    >{test.review}</p>
                  </div>
                );
              })}
              <button
                type="button"
                onClick={postTestimonial}
                className="mx-auto d-block testimonial mt-2"
              >
                Post your Testimonial
                <FaArrowDown className="mx-2 text-white fs-5" />
              </button>
              {/* Testimonial form */}
              <div className="testimonial-form">
                <form
                  style={{ display: "none" }}
                  id="my-form"
                  onSubmit={testimonialSubmit}
                >
                  <table className="table mb-5 mt-5">
                    <tbody>
                      <tr>
                        <td>Your Name<small className="testimonial-star"><i className="fa fa-star"></i></small>:</td>
                        <td>
                          <input
                            type="text"
                            name="name"
                            value={testimonialData.name}
                            onChange={testimonialChange}
                            className="p-1 w-100"
                          />
                          <p id="name-m" className="text-danger"></p>
                        </td>
                      </tr>
                      <tr>
                        <td>Company Name:</td>
                        <td>
                          <input
                            type="text"
                            name="company"
                            value={testimonialData.company}
                            onChange={testimonialChange}
                            className="p-1 w-100"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>
                          <input
                            type="email"
                            name="email"
                            value={testimonialData.email}
                            onChange={testimonialChange}
                            className="p-1 w-100"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Location:</td>
                        <td>
                          <input
                            type="text"
                            name="location"
                            className="p-1 w-100"
                            value={testimonialData.location}
                            onChange={testimonialChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile:</td>
                        <td>
                          <input
                            type="text"
                            name="mobile"
                            className="p-1 w-100"
                            value={testimonialData.mobile}
                            onChange={testimonialChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Rating<small className="testimonial-star"><i className="fa fa-star"></i></small>:</td>
                        <td>
                          <select
                            id="rating"
                            name="rating"
                            value={testimonialData.rating}
                            onChange={testimonialChange}
                            className="w-100 p-2"
                          >
                            <option value="select">--Select one--</option>
                            <option value="1">1 Star</option>
                            <option value="2">2 Star</option>
                            <option value="3">3 Star</option>
                            <option value="4">4 Star</option>
                            <option value="5">5 Star</option>
                          </select>
                          <p id="rating-m" className="text-danger"></p>
                        </td>
                      </tr>
                      <tr>
                        <td>Write Your Review<small className="testimonial-star"><i className="fa fa-star"></i></small></td>
                        <td>
                          <textarea
                            cols={46}
                            rows={3}
                            name="review"
                            value={testimonialData.review}
                            onChange={testimonialChange}
                            placeholder="Write review"
                            className="w-100 px-2 pt-1"
                          />
                          <p id="review-m" className="text-danger"></p>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <button type="submit" className="btn btn-danger">
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger mx-3"
                            onClick={cancelClick}
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
            {/* <PropertyByCity /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
