import React from "react";
import "../About/About.css";
import "./Services.css";
import Enquery from "./Enquery";
import PropertyByCity from "../PropertyByCity/PropertyByCity";


function RealStateAgent() {
  return (
    <div className="about-page-container">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mt-5 mb-5">
            <h5 className="about-page-head">Real Estate Agent in Varanasi</h5>
            <img src="Image/about-page.avif" alt="About-us" className="w-50 about-image" />
            <p style={{ textAlign: "justify", opacity: "0.9",fontSize:"14px" }}>
              Today, we are renowned as a potential real estate agent in
              Varanasi and the surrounding areas for encompassing the best of
              the localities therein for residential apartments and commercial
              complexes. We keep the specific demands of our valuable clients
              into consideration before getting to them with the most suitable
              property. This has been possible because of our vast database and
              a comprehensive knowledge of the market conditions. Buy Property
              If you wish to buy property in the commercial or residential
              sector in Varanasi and in areas lying in proximity to it, then we
              are the sole resort you should take to. A quick browse through our
              extensive database of properties to be bought in these areas will
              take you to the most apt choice. Sell Property Selling your
              property becomes a much simplified affair with our services. We
              provide your property, be it in the residential or the commercial
              sector, the much desired visibility so that you come across with
              the genuine buyers interested in paying the right amount your
              property is worth of. Rent/Lease Property Residential and
              commercial properties in Varanasi and the areas nearby have come
              to be rented in a much higher rate than earlier. We uphold
              transparent deals between the landlords and the tenants so that a
              long term healthy relationship is maintained between them. We also
              keep our self abreast with the latest property rental rates for
              the facilitation of our clients. Property Consultant We, owing to
              our impeccable experience of around 30 years, have emerged as a
              potential property consultant in Varanasi and nearby areas. Based
              in Varanasi, we have seen the changing trends of the real estate
              industry herein. So, when our clients want to own a property in
              the commercial or residential sector, we exactly which area that
              property should be placed in and the kind of property we should
              cater to them with.
            </p>
            <Enquery />
          </div>
          <PropertyByCity />
        </div>
      </div>
    </div>
  );
}

export default RealStateAgent;
