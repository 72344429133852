import React, { useState } from "react";
import { API } from "../../API/RealEstate_API";
import { ToastContainer, toast } from "react-toastify";
import country from "./country.json";

function Form() {
  const [postProperty, setPostProperty] = useState({
    property_for: "",
    property_category: "",
    area: "",
    price: "",
    property_image: "",
    description: "",
    city: "",
    state: "",
    locality: "",
    user_name: "",
    user_email: "",
    user_address: "",
    user_city: "",
    user_country: "",
    user_phone: "",
  });

  const propertyChange = (e) => {
    if (e.target.files) {
      setPostProperty({ ...postProperty, [e.target.name]: e.target.files[0] });
    } else {
      setPostProperty({ ...postProperty, [e.target.name]: e.target.value });
    }
  };
  const propertySubmit = (e) => {
    e.preventDefault();
    // console.log(postProperty)
    let id_array = [
      "property_category",
      "area",
      "price",
      "city",
      "locality",
      "description",
      "user_name",
      "user_email",
      "user_address",
      "user_city",
      "user_country",
      "user_phone",
    ];
    let val = document.forms["my-form"];
    let blank_field = 0;
    for (let i = 0; i < id_array.length; i++) {
      if (!val[id_array[i]].value) {
        let message = "Field is not Blank";
        document.getElementById(id_array[i]).innerHTML = message;
        blank_field++;
        break;
      }
      else {
        document.getElementById(id_array[i]).innerHTML = "";
      }
    }
    if (blank_field === 0) {
      API.PostProperty(postProperty).then((response) => {
        toast.success(response.message);
      })
      setPostProperty({
        property_for: "",
        property_category: "",
        area: "",
        price: "",
        property_image: "",
        description: "",
        city: "",
        state: "",
        locality: "",
        user_name: "",
        user_email: "",
        user_address: "",
        user_city: "",
        user_country: "",
        user_phone: "",
      });
    }
  };

  const resetValue = () => {
    setPostProperty({
      property_for: "",
      property_category: "",
      area: "",
      price: "",
      property_image: "",
      description: "",
      city: "",
      state: "",
      locality: "",
      user_name: "",
      user_email: "",
      user_address: "",
      user_city: "",
      user_country: "",
      user_phone: "",
    });
  };
  return (
    <form
      onSubmit={propertySubmit}
      className="post-form"
      id="my-form"
    >
      <ToastContainer position="top-center" theme="light" />
      <div className="content-1">
        <div className="row g-0 sub-content">
          <span className="col-md-6">Basic Information</span>
          <span className="col-md-6 text-end">
            <span className="star">*</span> fields are mandatory
          </span>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 ">
            <p>Property For:</p>
          </div>
          <div className="col-md-8">
            <p>
              <label>
                <input
                  type="radio"
                  value="sell"
                  name="property_for"
                  onChange={propertyChange}
                />
                Sell
              </label>
              &nbsp;
              <label className="mx-2">
                <input
                  type="radio"
                  value="rent"
                  name="property_for"
                  onChange={propertyChange}
                />
                Rent
              </label>
            </p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Property Category:
            </p>
          </div>
          <div className="col-md-8">
            <select
              name="property_category"
              onChange={propertyChange}
              value={postProperty.property_category}
              className="w-100 p-2"
              style={{ border: "1px solid rgba(0,0,0,.2)" }}
            >
              <option value="" defaultValue="selected">
                -- Select --
              </option>
              <option value="Flats/Apartments">--Flats/Apartments</option>
              <option value="House/Villas">--House/Villas</option>
              <option value="Builder Floors">--Builder Floors</option>
              <option value="Residential Plots">--Residential Plots</option>
              <option value="Studio Apartments">--Studio Apartments</option>
              <option value="Commercial Shops">--Commercial Shops</option>
              <option value="Showrooms">--Showrooms</option>
              <option value="Office Spaces">--Office Spaces</option>
              <option value="Business Center">--Business Center</option>
              <option value="Farm Land">--Farm Land</option>
              <option value="Commercial Land">--Commercial Land</option>
              <option value="Industrial Land">--Industrial Land</option>
              <option value="Guest House">--Guest House</option>
              <option value="Hotels">--Hotels</option>
              <option value="Warehouse">--Warehouse</option>
              <option value="Factory">--Factory</option>
            </select>
            <small id="property_category"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Area:
            </p>
          </div>
          <div className="col-md-8">
            <div className="row area">
              <div className="col-md-6">
                <input
                  type="text"
                  name="area"
                  value={postProperty.area}
                  onChange={propertyChange}
                  className="input"
                />
                <small id="area"></small>
              </div>
              <div className="col-md-6">
                <select name="unit_measure" className="w-100 p-2"
                  style={{ border: "1px solid rgba(0,0,0,.2)" }}>
                  <option value="Sq.ft.">Sq.ft.</option>
                  <option value="Cent">Cent</option>
                  <option value="Sq. Yards">Sq. Yards</option>
                  <option value="Ares">Ares</option>
                  <option value="Acre">Acre</option>
                  <option value="Sq. Meter">Sq. Meter</option>
                  <option value="Bigha">Bigha</option>
                  <option value="Hectares">Hectares</option>
                  <option value="Guntha">Guntha</option>
                  <option value="Marla">Marla</option>
                  <option value="Dismil">Dismil</option>
                  <option value="Biswa">Biswa</option>
                  <option value="Katha">Katha</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Price:
            </p>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="price"
              onChange={propertyChange}
              value={postProperty.price}
              className="input"
              placeholder="INR"
            />
            <small id="price"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>Property Image:</p>
          </div>
          <div className="col-md-8">
            <input
              type="file"
              onChange={propertyChange}
              name="property_image"
            />
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Property Description
            </p>
          </div>
          <div className="col-md-8">
            <textarea
              name="description"
              onChange={propertyChange}
              value={postProperty.description}
              maxLength={1000}
              rows={3}
            />
            <small id="description"></small>
          </div>
        </div>
      </div>
      <div className="content-2">
        <div className="row g-0 sub-content">
          <div className="col-md-12">
            <span>Property Location</span>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <p><b className="star">*</b>City:</p>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="city"
              onChange={propertyChange}
              value={postProperty.city}
              className="input"
            />
            <small id="city"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>State:</p>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="state"
              onChange={propertyChange}
              value={postProperty.state}
              className="input"
            />
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-3">
            <p><b className="star">*</b>Locality:</p>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="locality"
              onChange={propertyChange}
              value={postProperty.locality}
              className="input"
            />
            <small id="locality"></small>
          </div>
        </div>
      </div>
      <div className="content-3 mb-4">
        <div className="row g-0 sub-content">
          <div className="col-md-12">
            <span>Your Contact Details</span>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Your Name:
            </p>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="user_name"
              onChange={propertyChange}
              value={postProperty.user_name}
              className="input"
            />
            <small id="user_name"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> E-mail:
            </p>
          </div>
          <div className="col-md-8">
            <input
              type="email"
              name="user_email"
              onChange={propertyChange}
              value={postProperty.user_email}
              className="input"
            />
            <small id="user_email"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Address:
            </p>
          </div>
          <div className="col-md-8">
            <textarea
              name="user_address"
              onChange={propertyChange}
              rows={3}
              value={postProperty.user_address}
            />
            <small id="user_address"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> City /State:
            </p>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="user_city"
              onChange={propertyChange}
              value={postProperty.user_city}
              className="input"
            />
            <small id="user_city"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Select Country:
            </p>
          </div>
          <div className="col-md-8">
            <select
              name="user_country"
              onChange={propertyChange}
              value={postProperty.user_country}
              className="w-100 p-2"
              style={{border:"1px solid rgba(0,0,0,.2)"}}
            >
              {country.map((country) => {
                return (
                  <option value={country.name} key={country.code}>{country.name}</option>
                )
              })}
            </select>
            <small id="user_country"></small>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p>
              <b className="star">*</b> Phone / Mobile:
            </p>
          </div>
          <div className="col-md-8">
            <input
              type="tel"
              name="user_phone"
              onChange={propertyChange}
              maxLength={15}
              value={postProperty.user_phone}
              className="input"
            />
            <small id="user_phone"></small>
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-12 d-flex justify-content-center">
            <input
              type="submit"
              name="submit"
              defaultValue="Submit"
              className="btn btn-danger btn-sm"
            />
            <input
              type="reset"
              name="reset"
              defaultValue="Reset"
              onClick={resetValue}
              className="btn btn-danger btn-sm mx-2"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Form;
