import React from "react";

function PageNotFound() {
  return (
    <div
      className="container text-center"
      style={{ display: "flex", flexDirection: "column",marginBottom:"171px" }}
    >
      <h4 className="mt-5">404 Page Not Found</h4>
    </div>
  );
}

export default PageNotFound;
